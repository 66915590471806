import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

const App = React.lazy(() => import('./App'));

const container = document.querySelector('#root');
const root = createRoot(container!);
root.render(
  <Suspense>
    <App />
  </Suspense>,
);
